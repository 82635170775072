#GameWrapper {
    display: grid;
    grid-template-columns: 200px repeat(2, 1fr) 200px;
    grid-template-rows: 200px repeat(2, 1fr) 300px;
    grid-gap: 16px;
    height: 100%;
    width: 100%;
    grid-template-areas:  
    "Profile View View Boosts"
    "Profile View View Boosts"
    "Info View View Log"
    "Info Chat Chat Log";
}

#GameWrapper > Div {
    /* background-color: rgb(242, 128, 47); */
    overflow: auto;
}

#ProfileWrapper {
    grid-area: Profile;
}

#ViewWrapper {
    grid-area: View;
}

#ChatWrapper {
    grid-area: Chat;
}

#LogWrapper {
    grid-area: Log;
}

#BoostWrapper {
    grid-area: Boosts;
}

#InfoWrapper {
    grid-area: Info;
}
    

.paperHeader {
    position: relative;
    padding-bottom: 6px;
    margin-bottom: 8px;
    border-bottom: 2px solid #ddd;
    color: #7b7b7b;
    text-transform: uppercase;
    padding-top: 8px;
    padding-left: 16px;
    display: flex;
}