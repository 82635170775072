#home {
    width: 100%;
    height: 100%;
    background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/Background-portal.jpg");
    background-repeat: round;
}

#home #header {
    width: 100%;
    height: 80px;
}

#home #header #centerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

#home #header .header {
    height: 60px;
    background-color: black;
    opacity: 0.8;
    display: flex;
    justify-content: space-evenly;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 4px 4px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
    align-items: center
}

#home #header #left {
    width: 200px;

}

#home #header #center {
    width: 440px;
}

#home #header #right {
    width: 200px;
}



#home #header #center .title {
    color: white;
    width: 120px;
    font-size: 50px;
}

#home #header #center #logoWrapper {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    background-color: black;
    opacity: 1;
    box-shadow: 0px 3px 7px 4px rgb(255 255 255 / 69%);
    display: flex;
    justify-content: center;
    top: 0px;
    align-self: baseline;
}

#home #header #logo {
    height: 116px;
    /* padding-top: 5px; */
    opacity: 0.7;
    background-color: white;
    border-radius: 150px;
    width: 116px;
    margin-top: 2px;
}

#home #main #loginDialog  {
    display: none;
    width: 510px;
    height: 680px;
    background: white;
    background-image: url('https://opusvryeassets.fra1.digitaloceanspaces.com/ornamental-frameRotated.jpg');
    background-size: contain;
    margin: auto;
    margin-top: 90px;
    border-radius: 15px;
    box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.81);
    /* padding: 8px; */
}

#home #main #loginDialog.open {
    display: flex !important;
    flex-direction: column;
}

#home #main #loginDialog #dialogContent {
    width: calc(510px - 180px);
    height: 680px;
    margin: 90px;
    display: flex;
}