#app {
  display: flex;
}

#app > div {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 350px;
  align-items: center;
  justify-content: center;
}