.letter {
    height: 50px;
    width: 36px;
    background-size: cover;
    background-position-x: -10px;
}

.letter.a {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/a.png");}
.letter.b {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/b.png");}
.letter.c {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/c.png");}
.letter.d {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/d.png");}
.letter.e {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/e.png");}
.letter.f {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/f.png");}
.letter.g {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/g.png");}
.letter.h {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/h.png");}
.letter.i {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/i.png");}
.letter.j {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/j.png");}
.letter.k {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/k.png");}
.letter.l {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/l.png");}
.letter.m {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/m.png");}
.letter.n {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/n.png");}
.letter.o {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/o.png");}
.letter.p {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/p.png");}
.letter.q {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/q.png");}
.letter.r {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/r.png");}
.letter.s {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/s.png");}
.letter.t {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/t.png");}
.letter.u {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/u.png");}
.letter.v {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/v.png");}
.letter.w {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/w.png");}
.letter.x {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/x.png");}
.letter.y {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/y.png");}
.letter.z {background-image: url("https://opusvryeassets.fra1.digitaloceanspaces.com/letters/z.png");}